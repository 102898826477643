<template>
  <div class="user-list">
    <error :errors="userError" />
    <!-- <b-overlay :show="formBusy"> -->
      <portlet fluidHeight>
        <template v-slot:body>
          <div class="d-flex align-items-center justify-content-between">
            <b-pagination
                class="mb-0 mr-2"
                v-model="usersMeta.current_page"
                :total-rows="usersMeta.total"
                :per-page="usersMeta.per_page"
                aria-controls="user"
                @change="onChangePage"
            />
            <div class="kt-searchbar d-none d-sm-flex w-25">
              <div class="kt-input-icon kt-input-icon--left">
                <b-input @blur="refreshList" v-model="name" type="search" class="form-control" placeholder="ئەزا تور نامىنى كىرگۈزۈپ ئىزدەڭ" />
                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                    <span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                          <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                        </g>
                      </svg></span>
                  </span>
              </div>
            </div>
            <div class="actions">
              <button @click="refreshList" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm btn-icon btn-icon-md">
                <i class="flaticon2-refresh-arrow"></i>
              </button>
            </div>
          </div>
        </template>
      </portlet>
      <b-row>
        <b-col v-for="user in users" md="3" :key="user.id" sm="6">
          <portlet title=" " fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:toolbar>
              <b-form-checkbox v-b-tooltip title="سالونچىمۇ؟" :key="`${user.id}_super_inviter`" switch @change="onSwitchIsSuperInviter(user.id)" v-model="user.is_super_inviter"/>
            </template>
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head">
                  <div class="kt-widget__media">
                    <b-img class="kt-widget__img" :class="{'kt-hidden': ! user.avatar}" rounded="" height="50" :src="user.avatar" alt="image" />
                    <div :class="{'kt-hidden': user.avatar}" class="kt-widget__pic kt-widget__pic--success kt-font-success kt-font-boldest">
                      {{ user.nickname }}
                    </div>
                  </div>
                  <div class="kt-widget__info">
                    <a class="kt-widget__username">
                      {{ user.nickname }}
                    </a>
                    <a class="kt-widget__userid">
                      ID:{{ user.id }}
                    </a>
                    <span class="kt-widget__desc">{{ user.is_invitor ? 'ۋاكالەتچى' : 'ۋاكالەتچى ئەمەس'}}</span>
                  </div>
                </div>
                <div class="kt-widget__body">
<!--                  <div class="kt-widget__section"></div>-->
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label"> ئالى ئەزامۇ ؟ </span>
                      <span class="kt-widget__data">{{ user.is_vip ? user.vip_expires_str : 'ياق'}}</span>
                    </div>
                    <div v-if="user.is_invitor" class="kt-widget__contact">
                      <span class="kt-widget__label"> ئەكىرگەن ئەزا سانى </span>
                      <span class="kt-widget__data">{{ user.invitees_count}}</span>
                    </div>
                  </div>
                </div>
                <div class="kt-widget__footer">
                  <b-row>
                    <b-col md="6">
                    <b-button :to="{name: 'user.details', params: {id: user.id}}" :disabled="!user.is_invitor" type="button" variant="primary" :style="! user.is_invitor ? 'cursor: not-allowed' : ''">كىرىم تەپسىلاتى</b-button>
                    </b-col>
                    <b-col md="6">
                      <b-button :to="{name: 'user.vip', params: {id: user.id}}" type="button" variant="primary" >ئالىي ئەزالىق</b-button>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <portlet v-if="users.length < 1" class="kt-callout">
        <template v-slot:body>
          <div class="kt-callout__body">
            <div class="kt-callout__content">
              <h3 class="kt-callout__title">ئۇچۇر تېپىلمىدى</h3>
              <p class="kt-callout__desc">
                ھازىرچە مۇناسىۋەتلىك ئەزا ئ‍ۇچۇرى تېپىلمىدى
              </p>
            </div>
            <!--<div class="kt-callout__action">
              <b-button :to="{name: 'authors.create'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-success">يېڭىدىن قوشۇش</b-button>
            </div>-->
          </div>
        </template>
      </portlet>
    <!-- </b-overlay> -->
    <ratio-modal :state="ratioState" :options="ratioFormData" :user="ratioUser" @close="ratioState = false" @updated="ratioState = false" />
  </div>
</template>

<script>
  import Portlet from "../../../partials/content/Portlet";
  import Error from "../../../partials/widgets/Error";
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import { mapGetters } from "vuex";
  import { GET_USER_LIST } from "../../../../store/types";
  import RatioModal from "../../../components/user/RatioModal";
  import Swal from "sweetalert2";
  import userMixin from "../../../../mixins/userMixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  export default {
    name: "index",
    components: { Error, Portlet, RatioModal },
    mixins: [ userMixin, formBusyMixin ],
    computed: {
      ...mapGetters(['users', 'usersMeta', 'userError']),
    },
    created() {
      this.getUsersList();
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئەزا باشقۇرۇش", route: "list" },
        { title: "بارلىق ئەزالار" }
      ]);
    },
    data(){
      return {
        tableFields: [
          { key: "id", label: "id", class: ['d-none', 'd-md-table-cell'] },
          { key: "avatar", label: "باش سۆرىتى", class: ['d-none', 'd-md-table-cell'] },
          { key: "nickname", label: "تور نامى" },
          { key: "vip_expires_in_ago", label: "ئەزالىق ۋاقتى" },
          { key: "is_super_inviter", label: "سالونچىمۇ ؟" },
          { key: "income", label: "كىرىم ئەھۋالى" },
          { key: "actions", label: "مەشغۇلات" }
        ],
        tableBusy: false,
        ratioState: false,
        ratioFormData: {},
        ratioUser: {},
        page: 1,
        name: ''
      };
    },
    methods: {
      getUsersList(options = {page: 1, name: ''}){
        this.showBusy();
        this.page = options.page;
        this.$store.dispatch(GET_USER_LIST, options)
          .then(()=>{
            this.hideBusy();
          })
          .catch(()=>{
            this.hideBusy();
          })
      },
      onChangePage(page = 1){
        let name = this.name;
        this.getUsersList({page, name});
      },
      refreshList(){
        let name = this.name;
        this.getUsersList({page: 1, name});
      },
      searchUser(){
        let name = this.name;
        this.getUsersList({page: 1, name});
      },
      setRatio(user){
        this.ratioFormData = user.profit_ratio;
        this.ratioUser = user;
        this.ratioState = true;
      },
      copyAccount(row){
        this.$clipboard(row.item.hash_id);
        Swal.fire({
          text: 'كۆچۈرۈش مۇۋاپىقىيەتلىك بولدى!',
          icon: 'success'
        });
      },
      onSwitchIsSuperInviter(id){
        this.switchUserState(id, {column: 'is_super_inviter'});
      }
    }
  }
</script>

<style>
  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__section {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 74px;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }
  .kt-widget__userid {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
    font-size: 14px;
  }
</style>
