<template>
  <div class="ratio-modal">
    <b-modal @hidden="hiddenModal" centered v-model="modalState" size="lg" id="setRatio" hide-footer title="پايدا نىسپىتى تەڭشىكى">
      <b-overlay :show="formBusy" rounded="sm">
        <b-form>
          <b-input-group>
            <b-form-input v-model="formData.ratio" min=1 max=100 number type="number" placeholder="پايدا نىسپەت قىممىتىنى كىرگۈزۈڭ"></b-form-input>
            <b-input-group-append>
              <b-button @click="onSubmit" size="sm" variant="primary">ساقلاش</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
  import userMixin from "../../../mixins/userMixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  export default {
    name: "RatioModal",
    props: {
      state: {
        type: Boolean,
        default: false
      },
      options: {
        type: Object
      },
      user: {
        type: Object
      }
    },
    mixins: [ userMixin, formBusyMixin ],
    computed: {
      modalState: {
        get(){
          return this.state;
        },
        set(v){
          return v;
        }
      }
    },
    watch: {
      options: function(newOptions) {
        // console.log(newOptions);
        this.formData = newOptions || {ratio: '', user_id: this.user.id}
      }
    },
    data(){
      return {
        busy: false,
        formData: {
          ratio: ''
        }
      };
    },
    methods: {
      hiddenModal(event){
        this.$emit('close');
      },
      onSubmit(){
        this.setRatio(this.formData)
          .then(()=> {
            this.$emit('updated');
          })
      }
    }
  }
</script>

<style scoped>

</style>
